<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="$router.back()">
            <ion-icon :icon="chevronBackSharp"></ion-icon>
            Retour
          </ion-button>
        </ion-buttons>
        <ion-title>{{ secteur.nom }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-text>
        <div class="h1-image" :style="{
            backgroundImage: 'url(' + photo + ')',
            color:'white'
        }">
          <h1 v-html="secteur.nom"
              :style="{ backgroundColor: hexToRgbA(secteur.couleur, .8) }"

          />
        </div>

      </ion-text>
      <!-- Lecteur audio -->
      <green-audio-player
          v-if="audio"
          :song="audio"
          titre="Présentation du secteur"
          ref="audio"
      />
      <div class="liste-metiers">
        <ListeIlotsSecteurs
            v-if="ilots.length"
            :titre="'Ilots du secteur&nbsp;<span style=\'color:' +
            secteur.couleur +'\'> ' + secteur.numero +'</span>'"
            :ilots="ilots"
            :secteur="secteur"
        />
      </div>
      <created-by/>
    </ion-content>
  </ion-page>
</template>

<script>
import GreenAudioPlayer from '@/components/GreenAudioPlayer'
import CreatedBy from '@/components/CreatedBy'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonIcon,
  IonText,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton
} from "@ionic/vue";
import {mapGetters} from "vuex";
import {
  chevronBackSharp
} from "ionicons/icons";
import ListeIlotsSecteurs from "@/components/ListeIlotsSecteur";

export default {
  name: 'PageDetailSecteur',
  setup() {
    return {chevronBackSharp};
  },
  components: {
    ListeIlotsSecteurs,
    IonContent,
    IonHeader,
    IonPage,
    IonIcon,
    IonText,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    CreatedBy,
    GreenAudioPlayer
  },
  props: {
    id: {
      required: true
    }
  },
  data() {
    return {
      isPlaying: false
    }
  },
  computed: {
    ...mapGetters(['getSecteur']),
    secteur() {
      return this.getSecteur(this.id)
    },
    ilots() {
      return this.secteur.ilots.filter(ilot => ilot.visible) || []
    },
    audio() {
      return this.secteur.audio
          ? require('@/assets/audio/secteurs/' + this.secteur.audio)
          : null
    },
    photo() {
      return this.secteur.photo
          // ? require('@/assets/secteurs/photos/'+ this.secteur.photo)
          ? require('@/assets/metiers/' + this.secteur.photo)
          : null
    }
  },
  methods: {
    hexToRgbA(hex, a) {
      let c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
            ',' + a + ')';
      }
      throw new Error('Bad Hex');
    }
},
/* beforeRouteLeave (to, from, next) {
this.$refs.audio.togglePlay()
next()
}, */
}
</script>

<style scoped lang="sass">

.h1-image
  background-size: cover
  background-position: center center
  line-height: 1.2

h1
  margin: 0
  padding: .5rem 1rem 75px 1rem
  min-height: 220px
  line-height: 1.2
  font-size: 2.5em
  text-shadow: 1px 1px 2px rgba(0,0,0,.8)

.green-audio
  margin-top: -65px
</style>
