<template>
  <ion-list id="liste-ilots">
    <ion-list-header v-if="titre" v-html="titre" />
    <ion-item-group
      lines="full"
      v-for="ilot in ilots"
      :key="ilot.id"
    >

      <ion-item-divider :style="{ backgroundColor: secteur.couleur }">
        <div slot="start"
             v-if="ilot.numero > 0"
        >{{ilot.numero}}</div>
        <ion-label class="ion-text-wrap">
          {{ ilot.nom }}
        </ion-label>
      </ion-item-divider>

      <ion-item
          v-for="metier in metiersIlot(ilot.id)"
          :key="metier.id"
          button
          @click="$router.push({name: 'ficheMetier', params: { id: metier.id } })"
      >
        <ion-label class="ion-text-wrap">
          {{ metier.nom }}
        </ion-label>
      </ion-item>

      <!--
      <ion-icon v-if="metier.titre"
                slot="start"
                color="primary"
                :icon="this[getSecteurIcone(metier)]"></ion-icon>

      <ion-icon slot="start"
                color="dark"
                :icon="informationCircleSharp"></ion-icon>
       -->

    </ion-item-group>
  </ion-list>
</template>

<script>
import {
  IonList,
  IonListHeader,
  IonItem,
  IonLabel,
  IonItemDivider,
  IonItemGroup
}
  from
      '@ionic/vue'
import {
    hammerSharp,
    heartSharp,
    leafSharp,
    cartSharp,
    cogSharp,
    schoolSharp,
    constructSharp,
    carSportSharp,
    colorPaletteSharp,
    codeSlashSharp,
    restaurantSharp,
    wifiSharp,
    informationCircleSharp,
    trophySharp,
    fitnessSharp,
    fingerPrintSharp,
    briefcaseSharp



} from 'ionicons/icons'
import {mapGetters} from "vuex";

export default {
  name: 'ListeIlotsSecteurs',
  components: {
    IonList, IonListHeader, IonItem, IonLabel,   IonItemDivider,
    IonItemGroup
  },
  setup() {
    return {
      hammerSharp,
      heartSharp,
      leafSharp,
      cartSharp,
      cogSharp,
      schoolSharp,
      constructSharp,
      carSportSharp,
      colorPaletteSharp,
      codeSlashSharp,
      restaurantSharp,
      wifiSharp,
      informationCircleSharp,
      trophySharp,
      fitnessSharp,
      fingerPrintSharp,
      briefcaseSharp

    };
  },
  props: {
    titre: { required: false },
    ilots: { required: true },
    secteur: { required: true },
  },
  computed: {
    ...mapGetters(['getMetierIlots']),
    metiersAZ() {
      const met = this.metiers
      return met.sort((a, b) => a.nom.localeCompare(b.nom, 'fr'))
    }
  },
  methods: {
    metiersIlot(idIlot) {
      return this.getMetierIlots(idIlot)
    },
    getSecteurIcone(metier) {
      return (metier.secteurs[0] && metier.secteurs[0].icone)
          ? metier.secteurs[0].icone
          : 'constructSharp'
    }
  },
}
</script>

<style scoped lang="sass">
#liste-ilots

  ion-list-header
    margin-bottom: .5rem

  ion-item-divider
    padding-top: 10px
    padding-bottom: 10px
    font-weight: bold
    color: var(--ion-color-primary-contrast)
    text-shadow: 1px 1px 1px rgba(0,0,0,.8)

    div
      padding-right: 10px
      margin-left: -10px
      font-size: 1.5em
</style>
